<template>
  <div class="group-registration">
    <div class="mb-4 title">Group registration</div>
    <div v-if="isLoading" class="mb-4">Loading...</div>
    <div v-if="!isLoading" class="mb-4">
      <div v-if="!ungroupedAttributes.length" class="green--text">
        All migrations done
      </div>
      <div v-if="ungroupedAttributes.length" class="error--text">
        Found {{ ungroupedAttributes.length }} ungrouped question/s
      </div>
    </div>
    <div v-if="ungroupedAttributes.length" class="text-left">
      <v-btn
        round
        class="mx-0 sw-accent-bg sw-on-accent text-none"
        @click="updateSignupAttributes"
        :loading="isLoading"
        ><span>
          Migrate {{ ungroupedAttributes.length }} ungrouped question/s
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    signupPlugin: {},
    signupCategory: {},
    signupAttributes: [],
    signupTags: [],
  }),
  computed: {
    ungroupedAttributes() {
      return this.signupAttributes.filter((attr) => !attr.tags.length);
    },
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.fetchAll();
      },
    },
  },
  methods: {
    async fetchAll() {
      try {
        this.isLoading = true;

        await this.getSignupPluginSilent();
        await this.getSignupCategorySilent();
        await this.listSignupAttributesSilent();
        await this.listSignupAttributeTagsSilent();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    async getSignupPluginSilent() {
      if (!this.groupId) return;

      const params = [this.groupId, { prefix: "signup" }];

      const response = await this.$api.groupPlugins.list(...params);

      this.signupPlugin = response.data.data[0] || {};
    },
    async getSignupCategorySilent() {
      if (!this.groupId || !this.signupPlugin.id) return;

      const params = [
        this.groupId,
        {
          group_plugin_id: this.signupPlugin.id,
          per_page: 1,
        },
      ];

      const response = await this.$api.groupUserAttributeCategories.list(
        ...params,
      );

      this.signupCategory = response.data.data[0] || {};
    },
    async listSignupAttributesSilent() {
      if (!this.groupId || !this.signupCategory.id) return;

      const params = [
        this.groupId,
        {
          categories: this.signupCategory.id,
          per_page: 1000,
        },
      ];

      const response = await this.$api.groupUserAttributes.list(...params);

      this.signupAttributes = response.data.data;
    },
    async listSignupAttributeTagsSilent() {
      if (!this.groupId || !this.signupPlugin.id) return;

      const params = [this.groupId, { group_plugin_id: this.signupPlugin.id }];

      const response = await this.$api.groupUserAttributeTags.list(...params);

      this.signupTags = response.data.data;
    },
    async createSignupAttributeTagsSilent() {
      if (!this.groupId || !this.signupPlugin.id) return;

      const specs = [
        this.groupId,
        {
          name: "Click here to change group name",
          description: "Click here to change group description",
          hide_name: false,
          hide_description: false,
          group_plugin_id: this.signupPlugin.id,
        },
      ];

      await this.$api.groupUserAttributeTags.create(...specs);
    },
    async updateSignupAttributeSilent(specs) {
      if (!specs) return;

      await this.$api.groupUserAttributes.update(...specs);
    },
    async updateSignupAttributes() {
      try {
        this.isLoading = true;

        if (!this.signupTags.length) {
          await this.createSignupAttributeTagsSilent();
          await this.listSignupAttributeTagsSilent();
        }

        if (!this.ungroupedAttributes.length) return;

        for await (const attribute of this.ungroupedAttributes) {
          const specs = [
            this.groupId,
            attribute.id,
            {
              tags: [this.signupTags[0].id],
            },
          ];

          await this.updateSignupAttributeSilent(specs);
        }

        await this.listSignupAttributesSilent();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
