<template>
  <v-container class="general">
    <PageTitle :title="'Migrations'" />
    <div v-if="isVuexLoading('listGroups')">Loading...</div>
    <template v-if="!isVuexLoading('listGroups')">
      <div v-if="groups.length" class="mb-4">
        <v-combobox
          v-model="group"
          item-text="name"
          :items="groups"
          placeholder="Group"
          auto-select-first
          attach
        >
        </v-combobox>
      </div>
      <template v-if="group && group.id">
        <GroupMessageTemplates :group-id="group.id" class="mb-5" />
        <GroupPlugins :group-id="group.id" class="mb-5" />
        <GroupRegistration :group-id="group.id" />
      </template>
    </template>
  </v-container>
</template>

<script>
import GroupMessageTemplates from "@/components/Migrations/GroupMessageTemplates.vue";
import GroupPlugins from "@/components/Migrations/GroupPlugins.vue";
import GroupRegistration from "@/components/Migrations/GroupRegistration.vue";

export default {
  data: () => ({
    group: null,
    groups: [],
  }),
  components: {
    GroupMessageTemplates,
    GroupPlugins,
    GroupRegistration,
  },
  mounted() {
    this.listGroups();
  },
  methods: {
    listGroups() {
      this.startVuexLoading("listGroups");

      const params = { managed: 1, with_attributes: 1, per_page: 1000 };

      this.$api.groups.list(params).then(
        (response) => {
          this.endVuexLoading("listGroups");

          this.groups = response.data.data.filter(
            (el) => el.attributes.is_complete,
          );
        },
        () => {
          this.endVuexLoading("listGroups");
        },
      );
    },
  },
};
</script>

<style scoped lang="scss"></style>
