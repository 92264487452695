<template>
  <div class="group-message-templates">
    <div class="mb-4 title">Group messages</div>
    <template
      v-if="
        !isVuexLoading('listGroupPlugins') &&
        !isVuexLoading('listGroupMessageTemplates') &&
        !isVuexLoading('listGroupMenus')
      "
    >
      <div v-if="!groupPlugins.length">
        <v-btn
          round
          class="mx-0 primary text-none"
          @click="createGroupPlugin"
          :loading="isVuexLoading('createGroupPlugin')"
          >Create group message template plugin
        </v-btn>
      </div>
      <div v-else-if="groupPlugins.length && groupMessageTemplates.length">
        <v-btn
          round
          class="mx-0 primary text-none"
          @click="updateGroupMessageTemplates"
          :loading="isVuexLoading('updateGroupMessageTemplates')"
          >Update {{ groupMessageTemplates.length }} group message template/s
        </v-btn>
      </div>
      <div v-else-if="groupPlugins.length && messageListPageDestination">
        <v-btn
          round
          class="mx-0 primary text-none"
          @click="updateGroupMenuDestinations"
          :loading="isVuexLoading('updateGroupMenuDestinations')"
          >Update group menu destinations
        </v-btn>
      </div>
      <div v-else class="green--text">All migrations done</div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    groupPlugins: [],
    groupMessageTemplates: [],
    groupMenus: [],
  }),
  computed: {
    groupMenu() {
      return this.groupMenus.find((el) => el.slug === "group_menu");
    },
    groupMenuDestinations() {
      return this.groupMenu ? this.groupMenu.destinations : [];
    },
    messageListPageDestination() {
      return this.groupMenuDestinations.find(
        (el) => el.component === "MessageListPage" && !el.group_plugin_id,
      );
    },
  },
  methods: {
    listGroupPlugins() {
      if (!this.groupId) return;

      this.startVuexLoading("listGroupPlugins");

      const params = [this.groupId, { prefix: "message", per_page: 1000 }];

      this.$api.groupPlugins.list(...params).then(
        (response) => {
          this.endVuexLoading("listGroupPlugins");
          this.groupPlugins = response.data.data;
        },
        () => {
          this.endVuexLoading("listGroupPlugins");
        },
      );
    },
    createGroupPlugin() {
      if (this.groupPlugins.length) return;
      if (!this.groupId) return;

      this.startVuexLoading("createGroupPlugin");

      const specs = [this.groupId, { name: "Messages", prefix: "message" }];

      this.$api.groupPlugins.create(...specs).then(
        (response) => {
          this.endVuexLoading("createGroupPlugin");
          this.listGroupPlugins();
        },
        () => {
          this.endVuexLoading("createGroupPlugin");
        },
      );
    },
    listGroupMessageTemplates() {
      if (!this.groupId) return;

      this.startVuexLoading("listGroupMessageTemplates");

      const params = [this.groupId, { per_page: 1000 }];

      this.$api.groupMessageTemplates.list(...params).then(
        (response) => {
          this.endVuexLoading("listGroupMessageTemplates");
          this.groupMessageTemplates = response.data.data.filter(
            (el) => !el.group_plugin_id,
          );
        },
        () => {
          this.endVuexLoading("listGroupMessageTemplates");
        },
      );
    },
    async updateGroupMessageTemplates() {
      if (!this.groupPlugins.length) return;
      if (!this.groupId) return;

      this.startVuexLoading("updateGroupMessageTemplates");

      for (const template of this.groupMessageTemplates) {
        const specs = [
          this.groupId,
          template.id,
          { group_plugin_id: this.groupPlugins[0].id },
        ];

        const response = await this.$api.groupMessageTemplates
          .update(...specs)
          .catch(() => {
            this.endVuexLoading("updateGroupMessageTemplates");
          });

        if (!response) {
          return this.endVuexLoading("updateGroupMessageTemplates");
        }
      }

      this.endVuexLoading("updateGroupMessageTemplates");

      this.listGroupMessageTemplates();
    },
    listGroupMenus() {
      if (!this.groupId) return;

      this.startVuexLoading("listGroupMenus");

      this.$api.groupMenus.list(this.groupId).then(
        (response) => {
          this.endVuexLoading("listGroupMenus");
          this.groupMenus = response.data.data;
        },
        () => {
          this.endVuexLoading("listGroupMenus");
        },
      );
    },
    updateGroupMenuDestinations() {
      if (!this.groupId) return;

      this.startVuexLoading("updateGroupMenuDestinations");

      const specs = [
        this.groupId,
        this.groupMenu.id,
        this.messageListPageDestination.id,
        {
          group_plugin_id: this.groupPlugins[0].id,
        },
      ];

      this.$api.groupMenuDestinations.update(...specs).then(
        (response) => {
          this.endVuexLoading("updateGroupMenuDestinations");
          this.listGroupMenus();
        },
        () => {
          this.endVuexLoading("updateGroupMenuDestinations");
        },
      );
    },
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.listGroupPlugins();
        this.listGroupMessageTemplates();
        this.listGroupMenus();
      },
    },
  },
};
</script>

<style scoped lang="scss"></style>
