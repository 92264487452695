<template>
  <div class="group-plugins">
    <template v-if="!isLoading">
      <v-layout
        v-if="outdatedGroupPlugins && outdatedGroupPlugins.length"
        row
        wrap
      >
        <v-flex xs12 class="mb-4 title font-weight-regular"
          >{{ outdatedGroupPlugins.length }} outdated group plugin/s</v-flex
        >
        <v-flex xs12>
          <v-btn
            round
            class="mx-0 primary text-none"
            :loading="isProcessing"
            @click="updateGroupPlugins"
            >Update
          </v-btn>
        </v-flex>
      </v-layout>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    isProcessing: false,
    groupPlugins: [],
    oldPrefixes: [
      "articles",
      "grouparticles",
      "messages",
      "apppages",
      "ticketwizards",
      "questionwizards",
    ],
  }),
  computed: {
    outdatedGroupPlugins() {
      return this.groupPlugins.filter((el) =>
        this.oldPrefixes.includes(el.prefix),
      );
    },
  },
  methods: {
    listGroupPlugins() {
      if (!this.groupId) return;

      this.isLoading = true;

      const params = [this.groupId, { per_page: 1000 }];

      this.$api.groupPlugins.list(...params).then(
        (response) => {
          this.isLoading = false;

          if (!response.data || !response.data.data) {
            return;
          }

          this.groupPlugins = response.data.data;
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    async updateGroupPlugins() {
      if (!this.groupId) return;

      this.isProcessing = true;

      try {
        for await (const groupPlugin of this.outdatedGroupPlugins) {
          const specs = [this.groupId, groupPlugin.id];

          if (groupPlugin.prefix === "articles") {
            specs.push({ prefix: "article" });
          }

          if (groupPlugin.prefix === "grouparticles") {
            specs.push({ prefix: "article" });
          }

          if (groupPlugin.prefix === "apppages") {
            specs.push({ prefix: "page" });
          }

          if (groupPlugin.prefix === "messages") {
            specs.push({ prefix: "message" });
          }

          if (groupPlugin.prefix === "ticketwizards") {
            specs.push({ prefix: "ticketwizard" });
          }

          if (groupPlugin.prefix === "questionwizards") {
            specs.push({ prefix: "questionwizard" });
          }

          const response = await this.$api.groupPlugins.update(...specs);

          if (!response.data || !response.data.data) {
            this.isProcessing = false;
            return;
          }

          this.groupPlugins = this.groupPlugins.filter(
            (el) => el.id !== groupPlugin.id,
          );

          this.isProcessing = false;
        }
      } catch (error) {
        this.isProcessing = false;
      }
    },
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.listGroupPlugins();
      },
    },
  },
};
</script>

<style scoped lang="scss"></style>
